$container-max-widths: (
  xl: 1600px
);

$theme-colors: (
  "primary": #0067f8,
  "error": #dd3a22,
  "warning": #f3bb1d,
  "success": #1acb7d
);

$font-family-base: "proxima-nova";

@import url('https://use.typekit.net/cwq0uzp.css');
@import "../node_modules/bootstrap/scss/bootstrap";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.navbar {

    a.nav-link, .nav-item { 
        font-weight: 500;
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 !important;

        border-bottom: 2px solid #fff;

        &.active {
            color: $primary !important;
            border-bottom: 2px solid $primary;
        }

        &:hover {
            color: $primary !important;
        }
    }
}

.navbar-transparent {
    background-color: rgba(0,0,0,0.8);
}

.card {
    border: 0px;

    &-title {
        margin-bottom: 0px;
    }
}

.shadow-sm {
    box-shadow: 0 0rem 0.25rem rgba(0, 0, 0, 0.2) !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f4f8ff;
}

.form-group {
    margin: 0.5rem 0;
}

.gp-status-btn > .btn {
    padding: 0.45rem 0.5rem;
}

.gp-border-bottom {
    border-bottom: 1px solid #f4f8ff;
}

.btn-group > .btn:not(:last-child), .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.gp-file-upload-btn {
    position: relative;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    input {
        position: absolute;
        font-size: 200px;
        opacity: 0;
        right: 0;
        top: 0;
    }
}

.modal-content {
    border: 0;

    .modal-header {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .modal-body {
        h6 {
            border-bottom: 1px solid #DBDCDE;
            padding-bottom: 0.5rem;
        }
    }
}

th.border-left, td.border-left {
    border-left: 2px solid #dee2e6 !important;
}

.width-100 {
    width: 100%;
}

.white-space-break-spaces {
    white-space: break-spaces;
    overflow-wrap: break-word;
}

.cursor-pointer {
    cursor: pointer;
}

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

.font-weight {
    &-500 {
        font-weight: 500;
    }
}

.page-selector {
    border-radius: 0;
    border-left: 0;
    width: 60px;
    margin: 0;

    &:hover {
        outline: none;
    }

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
    }
}